var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"tag":"div","rules":_vm.rules,"name":_vm.label || _vm.name,"vid":_vm.vid,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"keep-on-top",attrs:{"label-for":_vm.name || _vm.labelText,"label":_vm.labelText},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.labelText)}})]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{},[_c('b-dropdown',{ref:"bDropDown",class:Object.assign({}, classes,
                        {'validation-fail': errors.length > 0,
                        disabled: _vm.disabled,
                        'short-dropdown': _vm.shortDropdown}),attrs:{"variant":"outline-primary","name":_vm.name,"toggle-class":"select-button custom-select","disabled":_vm.disabled,"block":""},on:{"shown":_vm.focusSearchInput},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"text-left buttontext"},[_vm._v(" "+_vm._s(_vm.buttonText)+" "),_c('br')])]},proxy:true}],null,true),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},[_c('b-dropdown-form',[_c('b-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideSearch),expression:"!hideSearch"}],ref:"searchInput",attrs:{"type":"search","placeholder":"Search"},on:{"input":_vm.debounceUserFilter,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.focusResults.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filterEnter.apply(null, arguments)}]}})],1),(_vm.$slots.headerSlot)?_c('div',{staticClass:"px-4"},[_vm._t("headerSlot"),_c('b-dropdown-divider')],2):_vm._e(),(
                            _vm.noOptionsMessage &&
                                _vm.filteredOptions &&
                                _vm.filteredOptions.length == 0
                        )?_c('b-dropdown-item-button',{attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.noOptionsMessage)+" ")]):_vm._e(),_vm._l((_vm.userFilteredOptions),function(option,index){return _c('b-dropdown-item-button',{key:index,ref:("item" + index),refInFor:true,class:_vm.selectedOption === option
                                ? 'selected-option'
                                : '',attrs:{"value":_vm.value},on:{"click":function($event){return _vm.selectOption(option)}}},[_vm._t("optionText",function(){return [_vm._v(_vm._s(option[_vm.itemText]))]},{"option":option})],2)})],2)],1),(_vm.$slots.append)?_c('b-col',{staticClass:"ml-auto pl-0",attrs:{"cols":"auto"}},[_vm._t("append")],2):_vm._e()],1),(errors && errors.length > 0)?_vm._t("error-message",function(){return [_c('span',[_vm._v(_vm._s(errors[0]))])]}):_vm._e()],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }